//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "UseTerms",
    data() {
        return {
            useTerms: [],
            query: {
                Order: {
                    FieldName: "Id",
                    SortType: 1
                },
                Filter: [],
                PageNumber: 1,
                PageSize: 1,

            },
            isLoading: false
        };
    },
    methods: {
        load() {
            let self = this;
            if (!self.isLoading) {
                self.isLoading = true;
                self.axios
                    .post(process.env.VUE_APP_APIEndPoint + "UseTerms/PublicGetAll", self.query)
                    .then(function (res) {
                        self.useTerms = res.data.Data;

                    })
                    .finally(() => self.isLoading = false);
            }
        }
    },
    created() {
        this.load();
    },
    destroyed() {
    },
};
